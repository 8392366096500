<template>
  <div>
    <list
      ref="refRequirementList"
      sort-by="requerimientoId"
      key-field="requerimientoId"
      refetch-records-name="requerimientos"
      controller-name="AccionesMejora/Requerimiento"
      report-name="solicitudesRequerimientos.pdf"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
    >
      <template #cell(fecha)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fecha | formatDate }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fecha | formatDate }}
        </b-media>
      </template>
    </list>
  </div>
</template>

<script>
import { BMedia } from 'bootstrap-vue'
import { saveAs } from 'file-saver'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import requirementService from '@/services/requirement.service'

export default {
  components: {
    List,
    BMedia,
  },
  setup() {
    /* Refs */
    const refRequirementList = ref(null)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateRam')) {
      dateTimeFormater.push({ key: 'formatDateRam', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateRam'))

    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    /* Data */
    const { fetchRequirements, fetchReportFile } = requirementService()
    const requirements = ref([])
    // Methods
    const refetchRequirements = () => {
      fetchRequirements(data => {
        requirements.value = data.requerimientos
      })
    }
    const downloadReport = item => {
      fetchReportFile(item.requerimientoId, data => {
        const file = new File([data], `Reporte_requerimiento${item.requerimientoId}.pdf`)
        saveAs(file, `Reporte_requerimiento_${item.requerimientoId}.pdf`)
      })
    }
    /* Configuration */
    actions = ref([
      {
        label: 'Nuevo requerimiento',
        aclAction: 'create',
        aclResource: 'AccionesMejora',
        routeName: 'apps-requirement-new',
        params: { requerimientoId: 0 },
        icon: 'PlusCircleIcon',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    const filters = ref([])

    const tableColumns = [
      {
        label: 'No. Acción',
        key: 'numero',
        sortable: true,
      },
      {
        label: 'Fecha',
        key: 'fecha',
        sortable: true,
      },
      {
        label: 'Cliente interno / Externo',
        key: 'cliente.nombre',
        sortable: true,
      },
      {
        label: 'Indicador',
        key: 'indicador.nombre',
        sortable: true,
      },
      {
        label: 'Tipo de accion',
        key: 'accionMejoraTipo.nombre',
        sortable: true,
        sortKey: 'accionMejoraTipo.Nombre',
      },
      {
        label: 'Area ',
        key: 'areaTrabajo.nombre',
        sortable: true,
        sortKey: 'areaTrabajo.Nombre',
      },
      {
        label: 'Problema alto riesgo',
        key: 'esProblemaAltoRiesgoParaEmpresa',
        sortable: true,
      },
      {
        label: 'Accion efectiva',
        key: 'esAccionEfectiva',
        sortable: true,
      },
      {
        label: 'Estado',
        key: 'requerimientoEstado.nombre',
        sortable: true,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'AccionesMejora',
        routeName: 'apps-requirement-edit',
        params: ['requerimientoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'AccionesMejora',
        params: ['requerimientoId'],
        icon: 'TrashIcon',
      },
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        label: 'Descargar reporte',
        click: downloadReport,
        icon: 'ArrowDownCircleIcon',
      },
    ])
    return {
      // Refs & configurations
      refRequirementList,
      tableColumns,
      tableActions,
      actions,
      filters,
      stateTime,
      // Data
      requirements,
      // Methods
      downloadReport,
      refetchRequirements,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
